import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const ContentWithMedia = loadable(() => import('/src/components/PageBuilder/layouts/standard/ContentWithMedia'))
const ImageBulletList = loadable(() => import('/src/components/Lists/ImageBulletList'))

const Directions = ({ backgroundColour, altTitle, utm }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            directions {
                                fieldGroupName
                                directions {
                                    addTitleArea
                                    title
                                    description
                                    mediaLocation
                                    image {
                                        id
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(width: 700)
                                            }
                                        }
                                        altText
                                        title
                                    }
                                    itemsRepeater {
                                        image {
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(height: 150)
                                                }
                                            }
                                            altText
                                            title
                                        }
                                        title
                                        description
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        const componentData = node.globalBlocks.directions.directions

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: 'inline',
            title: altTitle ? altTitle : componentData.title,
            description: componentData.description
        }

        return (
            <ContentWithMedia
                key={index}
                backgroundColour={backgroundColour}
                titleArea={titleArea}
                columnSizes={'media-6-content-6'}
                mediaLocation={componentData.mediaLocation}
                mediaType={'image'}
                image={componentData.image}
                itemsRepeater={componentData.itemsRepeater}
                contentType={'custom'}
                customContent={<ImageBulletList itemsRepeater={componentData.itemsRepeater} />}
                className={'c-directions'}
                utm={utm}
            />
        )
    })
}

Directions.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    altTitle: PropTypes.string,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

Directions.defaultProps = {
    backgroundColour: 'light'
}

export default Directions

export const query = graphql`
    fragment DirectionsForPageBuilder on WpPage_Acfpagebuilder_Layouts_Directions {
        fieldGroupName
        backgroundColour
        altTitle
    }
`
